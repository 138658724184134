import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store/store';

export default function NavBar() {
  const navigate = useNavigate();
  const { t } = useTranslation(['header']);

  const { locale, setLocale } = useStore((state) => state);

  const switchLocale = () => {
    const newLocale = locale === 'fr-CA' ? 'en-US' : 'fr-CA';
    const currentPathArray = [...window.location.pathname.split('/')];
    currentPathArray[1] = newLocale;
    const newPath = currentPathArray.join('/');
    setLocale(newLocale);
    navigate(`${newPath}`);
  };

  return (
    <div className="header__nav-bar">
      <div className="container">
        <nav>
          <ul className="header__nav-bar__list">
            <li className="header__nav-bar__list-item">
              <Link
                to={`/${locale}/`}
                className="header__nav-bar__list-item__link"
              >
                {t('labelHome')}
              </Link>
            </li>
            <li className="header__nav-bar__list-item">
              <div className="header__nav-bar__list-item__locale">
                <button type="button" onClick={switchLocale}>
                  {locale === 'fr-CA' ? 'EN' : 'FR'}
                </button>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
