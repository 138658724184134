import { Notification as TelusNotification } from '@telus-uds/components-web';
import { useEffect } from 'react';

import useStore from '../../store/store';

export default function Notification() {
  const { formSubmissionStatus, setFormSubmissionStatus } = useStore(
    (state) => state
  );

  useEffect(() => {
    const notificationTimeout = setTimeout(() => {
      setFormSubmissionStatus({
        visible: false,
        type: '',
        message: '',
      });
    }, 5000);

    return () => {
      clearTimeout(notificationTimeout);
    };
  });

  return (
    <button
      type="button"
      className="notification-button-wrapper"
      onClick={() => {
        setFormSubmissionStatus({
          visible: false,
          type: '',
          message: '',
        });
      }}
    >
      <TelusNotification variant={{ style: formSubmissionStatus.type }}>
        {formSubmissionStatus.message}
      </TelusNotification>
    </button>
  );
}
