/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Checkbox as TelusCheckbox } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store/store';
import { getLabel } from '../util';

export default function Checkbox({ fieldData }) {
  const { t } = useTranslation(['form']);
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );

  const { id, name, required } = fieldData;
  const { value: fieldValue, error } = selectedFormValues[name];
  const isChecked = !!fieldValue;

  const handleOnChange = (value) => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        value,
        touched: true,
        ...(required && !value
          ? { error: t('labelFieldRequired') }
          : { error: '' }),
      },
    }));
  };

  const fieldProps = {
    id,
    label: getLabel(fieldData),
    ...(error && { error: true, feedback: error }),
    checked: isChecked,
    onChange: handleOnChange,
  };

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: '',
        },
      }));
    };
  }, []);

  return (
    <div id={id}>
      <TelusCheckbox {...fieldProps} />
    </div>
  );
}

Checkbox.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};
