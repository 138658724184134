const createGlobalSlice = (set) => ({
  locale: 'en-US',
  userProfile: null,
  showOverlay: false,
  platform: 'sof1',
  setPlatform: (platformValue) => set(() => ({ platform: platformValue })),
  setLocale: (localeValue) => set(() => ({ locale: localeValue })),
  setUserProfile: (userProfileValue) =>
    set(() => ({ userProfile: userProfileValue })),
  setShowOverlay: (data) => set(() => ({ showOverlay: data })),
});

export default createGlobalSlice;
