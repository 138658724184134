import { create } from 'zustand';
// import { persist } from 'zustand/middleware';

/** IMPORTING ALL STORE SLICES */
import createGlobalSlice from './slice/globalSlice';
import createFormSlice from './slice/formSlice';

// const useStore = create(
//   persist(
//     (...a) => ({
//       ...createGlobalSlice(...a),
//       ...createFormSlice(...a),
//     }),
//     { name: 'store-data' }
//   )
// );
const useStore = create((...a) => ({
  ...createGlobalSlice(...a),
  ...createFormSlice(...a),
}));

export default useStore;
