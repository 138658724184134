/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  FlexGrid,
  StackView,
  Typography,
} from '@telus-uds/components-web';

import useForm from '../../hooks/useForm';
import useStore from '../../store/store';
import Notification from '../../components/notification';
import LoadingSkeleton from './components/loadingSkeleton';
import NotAllowed from './components/notAllowed';
import {
  EXTERNAL_USERS,
  INTERNAL_USERS,
  CHANNELS,
} from '../../utils/constants';
import { flattenFormFields } from '../../utils/utils';
import useGetContentfulData from '../../hooks/useGetContentfulData';

export default function Form() {
  const navigate = useNavigate();
  const { formUrl } = useParams();
  const [loading, setLoading] = useState(false);
  const [notallowed, setNotallowed] = useState(false);

  const { data: contentfulFormData } = useGetContentfulData(formUrl);
  const { renderFormFields, handleFormSubmit } = useForm();
  const {
    userProfile,
    locale,
    selectedFormData,
    selectedFormValues,
    setSelectedFormData,
    setSelectedFormValues,
    formSubmissionStatus,
  } = useStore((state) => state);
  const { pageTitle, pageDescription } = selectedFormData || {};

  const checkFormVisibility = (visibility) => {
    const { DEALER } = CHANNELS;
    const { channelName } = userProfile;
    if (
      (visibility === EXTERNAL_USERS && channelName !== DEALER) ||
      (visibility === INTERNAL_USERS && channelName === DEALER)
    )
      setNotallowed(true);
  };

  useEffect(() => {
    setLoading(true);
    if (contentfulFormData) {
      if (!contentfulFormData.length) navigate(`/${locale}`);
      else {
        const {
          fields: {
            form: {
              fields: { formFields },
            },
            visibility,
          },
        } = contentfulFormData[0];

        if ([INTERNAL_USERS, EXTERNAL_USERS].includes(visibility)) {
          checkFormVisibility(visibility);
        }

        const updateField = (field) => {
          const {
            fields: { groupKey, groupFields },
          } = field;
          const result = { ...field };

          if (groupKey === 'GroupSite') {
            result.fields.groupFields = groupFields.map((i) => {
              const fields = { ...i.fields };
              fields.groupId = 'Site 1';
              return { ...i, fields };
            });
          }

          return result;
        };

        const formFieldInitialValues = formFields
          .map(updateField)
          .reduce(flattenFormFields, {});
        setSelectedFormValues(formFieldInitialValues);
        setSelectedFormData(contentfulFormData[0].fields);
        setLoading(false);
      }
    }
  }, [locale, formUrl, contentfulFormData]);

  return (
    <>
      {loading && <LoadingSkeleton />}
      {!loading && notallowed && <NotAllowed />}
      {!loading && !notallowed && selectedFormData && selectedFormValues && (
        <FlexGrid>
          <FlexGrid.Row>
            <FlexGrid.Col xs={12} sm={12} md={10} lg={7}>
              <StackView space={{ xs: 1, md: 3, xl: 5 }}>
                <Typography variant={{ size: 'display2' }} heading="h2">
                  {pageTitle}
                </Typography>
                <Typography variant={{ size: 'large' }}>
                  {pageDescription}
                </Typography>
                {renderFormFields()}
                {formSubmissionStatus.visible && <Notification />}
                <Button
                  variant={{ priority: 'high' }}
                  onPress={handleFormSubmit}
                >
                  Submit
                </Button>
              </StackView>
            </FlexGrid.Col>
          </FlexGrid.Row>
        </FlexGrid>
      )}
    </>
  );
}
