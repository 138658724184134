/* eslint-disable react/jsx-props-no-spreading */
// import { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@telus-uds/components-web';

import useStore from '../../../../../store/store';
import { getLabel } from '../../../../formFields/util';
import { REQUIRED_ADDRESS_FIELDS } from '../../../../../utils/constants';

export default function FieldTemplate({ parentFieldData, fieldData }) {
  const { t } = useTranslation(['form']);
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );
  const { fieldLabel, fieldKey, fieldRequired } = fieldData;
  const { name, required: parentFieldRequired } = parentFieldData;
  const { value: fieldValue, error } = selectedFormValues[name];

  const fieldError = !!(
    parentFieldRequired &&
    fieldRequired &&
    error &&
    !fieldValue[fieldKey]
  );

  const addressVaild = REQUIRED_ADDRESS_FIELDS.every(
    (key) => !!fieldValue[key]
  );

  const handleOnChange = (key, value) => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        ...(addressVaild && { error: '' }),
        value: { ...fieldValue, [key]: value },
      },
    }));
  };

  const handleOnBlur = () => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        ...(!addressVaild && { error: t('labelFieldRequired') }),
        touched: true,
      },
    }));
  };

  const fieldProps = {
    label: getLabel({ label: fieldLabel, required: fieldRequired }),
    value: fieldValue[fieldKey],
    ...(fieldError && {
      validation: 'error',
      feedback: error,
    }),
    onChange: (value) => handleOnChange(fieldKey, value),
    onBlur: handleOnBlur,
  };

  return <TextInput {...fieldProps} />;
}

FieldTemplate.propTypes = {
  parentFieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  fieldData: PropTypes.shape({
    fieldLabel: PropTypes.string,
    fieldKey: PropTypes.string,
    fieldRequired: PropTypes.bool,
  }).isRequired,
};
