import { Suspense, useEffect } from 'react';

import i18next from 'i18next';
import { BaseProvider } from '@telus-uds/components-web';
import alliumTheme from '@telus-uds/theme-allium';

import useStore from './store/store';
import ProjectRoutes from './routes/routes';

import { getLocale } from './utils/utils';

function App() {
  const { setLocale } = useStore((state) => state);
  const locale = getLocale();

  useEffect(() => {
    setLocale(locale);
    i18next.changeLanguage(locale.split('-')[0]);
  }, [locale, setLocale]);

  return (
    <Suspense fallback={null}>
      <BaseProvider defaultTheme={alliumTheme}>
        <ProjectRoutes />
      </BaseProvider>
    </Suspense>
  );
}

export default App;
