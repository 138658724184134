const createFormSlice = (set) => ({
  selectedFormData: {},
  selectedFormValues: {},
  formSubmissionStatus: {
    visible: false,
    type: '',
    message: '',
  },
  setSelectedFormData: (data) => set(() => ({ selectedFormData: data })),
  setSelectedFormValues: (fn) => {
    if (typeof fn === 'function')
      set((state) => ({
        selectedFormValues: fn(state.selectedFormValues),
      }));
    else
      set(() => ({
        selectedFormValues: fn,
      }));
  },
  setFormSubmissionStatus: (data) =>
    set(() => ({ formSubmissionStatus: data })),
});

export default createFormSlice;
