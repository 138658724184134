import PropTypes from 'prop-types';
import { Typography, Tooltip } from '@telus-uds/components-web';

import { getLabel } from '../../formFields/util';

export default function Header({ fieldData }) {
  const { hint, toolTip } = fieldData;
  return (
    <>
      <Typography block variant={{ bold: true }}>
        <div className="flex align-center gap-small">
          {getLabel(fieldData)}
          {toolTip && <Tooltip content={toolTip} />}
        </div>
      </Typography>
      {hint && (
        <Typography block variant={{ size: 'small' }}>
          {hint}
        </Typography>
      )}
    </>
  );
}

Header.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    fileTypes: PropTypes.arrayOf(PropTypes.string),
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
