import PropTypes from 'prop-types';
import { StackView } from '@telus-uds/components-web';

import useStore from '../../store/store';

import Header from './components/header';
import Upload from './components/upload';
import FileList from './components/fileList';
import Alert from '../alert';

export default function FileUpload({ fieldData }) {
  const { selectedFormValues } = useStore((state) => state);

  const { id, name } = fieldData;
  const { value: fieldValue, error: fieldError } =
    selectedFormValues[name] || {};
  const files = fieldValue || [];

  return (
    <div id={id}>
      <StackView space={2}>
        <Header fieldData={fieldData} />
        <Upload fieldData={fieldData} />
        <FileList files={files} fieldName={name} fieldError={fieldError} />
        {fieldError && <Alert message={fieldError} />}
      </StackView>
    </div>
  );
}

FileUpload.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    fileTypes: PropTypes.arrayOf(PropTypes.string),
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
