/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { TextInput } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store/store';
import { getLabel } from '../util';

export default function Email({ fieldData }) {
  const { t } = useTranslation(['form']);
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );

  const { id, name, toolTip, required, hint } = fieldData;
  const { value: fieldValue, error } = selectedFormValues[name];

  const isEmailValid = (email) =>
    !!email.match(
      /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  const handleOnChange = (value) => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        value,
        ...{
          error:
            previousValue[name].touched && !isEmailValid(value)
              ? t('labelEmailAddress')
              : '',
        },
      },
    }));
  };

  const handleOnBlur = () => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        touched: true,
        ...(required && !isEmailValid(fieldValue)
          ? {
              error: !fieldValue
                ? t('labelFieldRequired')
                : t('labelEmailAddress'),
            }
          : { error: '' }),
      },
    }));
  };

  const fieldProps = {
    label: getLabel(fieldData),
    ...(hint && { hint, hintPosition: 'below' }),
    ...(error && { validation: 'error', feedback: error }),
    ...(toolTip && { tooltip: toolTip }),
    value: selectedFormValues[name].value,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
  };

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: '',
        },
      }));
    };
  }, []);

  return (
    <div id={id}>
      <TextInput {...fieldProps} />
    </div>
  );
}

Email.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
