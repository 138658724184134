import { DEFAULT_ADDRESS_OBJECT } from './constants';

export const getPathName = () => window.location.pathname;

export const getLocale = () => getPathName().split('/')[1];

export const setLocalStorage = (key, value) => {
  if (key && value) window.sessionStorage.setItem(key, value);
};

export const getLocalStorage = (key) => window.sessionStorage.getItem(key);

export const flattenFormFields = (acc, current) => {
  const { fields } = current;
  const {
    sys: {
      id,
      contentType: {
        sys: { id: fieldType },
      },
    },
    fields: { name, type, groupFields },
  } = current;
  const fieldValue = type === 'Address' ? DEFAULT_ADDRESS_OBJECT : '';

  if (fieldType === 'formFieldsGroup')
    return { ...acc, ...groupFields.reduce(flattenFormFields, {}) };

  return {
    ...acc,
    [name]: {
      ...fields,
      category: fieldType,
      value: fieldValue,
      id,
      error: '',
      touched: false,
    },
  };
};
