import PropTypes from 'prop-types';
import { FlexGrid, Spacer } from '@telus-uds/components-web';

import Heading from './components/heading';
import Search from './components/search';
import Fields from './components/fields';

import useStore from '../../store/store';
import { REQUIRED_ADDRESS_FIELDS } from '../../utils/constants';

export default function AddressAutoComplete({ fieldData }) {
  const { selectedFormValues } = useStore((state) => state);
  const { id, name } = fieldData;
  const { value: fieldValue, error } = selectedFormValues[name];
  const validAddress = REQUIRED_ADDRESS_FIELDS.every(
    (fieldKey) => !!fieldValue[fieldKey]
  );
  const addErrorClass = !!(error && !validAddress);

  return (
    <div
      id={id}
      className={`form-field--group--main form-field--address ${
        addErrorClass && 'error'
      }`}
    >
      <FlexGrid>
        <FlexGrid.Row>
          <FlexGrid.Col xs={12}>
            <Heading fieldData={fieldData} />
          </FlexGrid.Col>
        </FlexGrid.Row>
        <Spacer space={4} />
        <FlexGrid.Row>
          <FlexGrid.Col xs={12}>
            <Search fieldData={fieldData} />
          </FlexGrid.Col>
        </FlexGrid.Row>
        <Spacer space={5} />
        <Fields fieldData={fieldData} />
      </FlexGrid>
    </div>
  );
}

AddressAutoComplete.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
