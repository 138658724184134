import PropTypes from 'prop-types';
import {
  StatusError,
  StatusSuccess,
  Delete,
} from '@telus-uds/palette-allium/build/web/icons';
import { Icon } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store/store';
import { getFileError } from '../util';

export default function SingleFile({ fileData, fileIndex, fieldName }) {
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );
  const { t } = useTranslation(['form']);

  const {
    file: { name, size },
    errors,
  } = fileData;
  const { required } = selectedFormValues[fieldName];

  const isRejectedFile = !!errors.length;
  const statusIcon = isRejectedFile ? StatusError : StatusSuccess;
  const variantColor = isRejectedFile ? 'danger' : 'success';

  const onDeleteFile = () => {
    setSelectedFormValues((previousValue) => {
      const files = [...previousValue[fieldName].value];
      files.splice(fileIndex, 1);
      const error = getFileError(files, t, required);
      return {
        ...previousValue,
        [fieldName]: {
          ...previousValue[fieldName],
          value: files,
          error: error || '',
        },
      };
    });
  };

  return (
    <li>
      <div className="form-field__file-upload__file-list__item">
        <Icon
          icon={statusIcon}
          variant={{ color: variantColor, size: 'small' }}
        />
        <p className="form-field__file-upload__file-list__item__filename">
          {name} - {(size / 1000000).toFixed(2)} MB
        </p>
        <button
          type="button"
          className="form-field__file-upload__file-list__item__delete"
          onClick={onDeleteFile}
        >
          <Icon icon={Delete} variant={{ size: 'small' }} />
        </button>
      </div>
      <ul className="form-field__file-upload__file-list__item__error">
        {errors.map((e) => (
          <li
            className="form-field__file-upload__file-list__item__error__item"
            key={e.code}
          >
            {e.message}
          </li>
        ))}
      </ul>
    </li>
  );
}

SingleFile.propTypes = {
  fileData: PropTypes.shape({
    file: PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.number,
    }),
    errors: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  fileIndex: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
};
