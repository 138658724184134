import { StackView, Skeleton } from '@telus-uds/components-web';

export default function LoadingSkeleton() {
  return (
    <StackView space={4}>
      <Skeleton characters={8} sizeIndex={10} />
      <Skeleton characters={20} sizeIndex={5} />
      <StackView space={7}>
        <Skeleton characters={30} sizeIndex={30} />
        <Skeleton characters={30} sizeIndex={30} />
        <Skeleton characters={30} sizeIndex={30} />
        <Skeleton characters={30} sizeIndex={30} />
      </StackView>
    </StackView>
  );
}
