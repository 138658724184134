export const getFileError = (files, t, isRequired) => {
  const fieldRequiredError = !files.length;
  const allInvalidFilesAttached =
    !!files.length && files.every((file) => !!file.errors.length);
  let error = '';
  if (isRequired && fieldRequiredError) error = t('labelFieldRequired');
  if (allInvalidFilesAttached) error = t('labelNoValidFileAttached');
  return error;
};

export const defaultFileTypes = ['.jpeg', '.jpg', '.png', '.pdf'];

export const typeMapping = {
  'application/pdf': '.pdf',
  'image/jpeg': '.jpeg',
  'image/jpg': '.jpg',
  'image/png': '.png',
  'application/vnd.ms-excel': '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '.xlsx',
  'text/csv': '.csv',
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    '.docx',
};
