/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { Outlet, useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import useStore from '../store/store';
import Error from '../pages/Error';
import { getLocalStorage, setLocalStorage } from '../utils/utils';

function validJson(text) {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
}

export default function PrivateRoutes() {
  const { locale, setUserProfile, setPlatform } = useStore((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const paramsValue = searchParams.get('key');

  useEffect(() => {
    const { token, platform } =
      validJson(paramsValue) && JSON.parse(paramsValue);

    const userToken = token || getLocalStorage('accessToken');
    const userPlatform = platform || getLocalStorage('userPlatform');

    if (!userToken) {
      navigate(`/${locale}/error`);
      return;
    }

    const apiHost =
      userPlatform === 'sof1'
        ? process.env.REACT_APP_SOF1_API_URL
        : process.env.REACT_APP_SOF2_API_URL;
    setLoading(true);
    axios
      .get(`${apiHost}/users/profile`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((response) => {
        const { data } = response;
        setLocalStorage('accessToken', userToken);
        setLocalStorage('userPlatform', userPlatform);
        setIsUserAuthorized(true);
        searchParams.delete('key');
        setSearchParams(searchParams);
        setUserProfile(data);
        setPlatform(platform);
      })
      .catch(() => {
        navigate(`/${locale}/error`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <h1>Loading...</h1>;

  return isUserAuthorized ? <Outlet /> : <Error />;
}
