import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';

import Layout from '../components/layout';

/** IMPORT PAGES */
import Dashboard from '../pages/dashboard';
import Form from '../pages/form';
import Error from '../pages/Error';

import PrivateRoutes from './privateRoutes';

export default function ProjectRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {/* PROTECTED ROUTES */}
          <Route path="/:locale" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path=":formUrl" element={<Form />} />
          </Route>
        </Route>
        {/* NON-PROTECTED ROUTES */}
        <Route path="/:locale" element={<Layout />}>
          <Route path="error" element={<Error />} />
        </Route>
        <Route path="*" element={<Navigate replace to="/en-US/" />} />
      </Routes>
    </BrowserRouter>
  );
}
