/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { TextInput } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store/store';
import { getLabel, validateField } from '../util';

export default function InputText({ fieldData }) {
  const { t } = useTranslation(['form']);
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );

  const { id, name, toolTip, required, hint } = fieldData;
  const { error } = selectedFormValues[name];

  const getError = (value) => {
    if (required && !value) return t('labelFieldRequired');

    const fieldError = validateField({ ...fieldData, value });
    return fieldError;
  };

  const handleOnChange = (value) => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: { ...previousValue[name], value, error: getError(value) || '' },
    }));
  };

  const handleOnBlur = (e) => {
    const {
      target: { value },
    } = e;

    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        touched: true,
        ...{ error: getError(value) || '' },
      },
    }));
  };

  const fieldProps = {
    label: getLabel(fieldData),
    ...(hint && { hint, hintPosition: 'below' }),
    ...(error && { validation: 'error', feedback: error }),
    ...(toolTip && { tooltip: toolTip }),
    value: selectedFormValues[name].value,
    onChange: handleOnChange,
    onBlur: handleOnBlur,
  };

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: '',
        },
      }));
    };
  }, []);

  return (
    <div id={id}>
      <TextInput {...fieldProps} />
    </div>
  );
}

InputText.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
