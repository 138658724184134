/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, Modal, StackView } from '@telus-uds/components-web';

import useStore from '../../store/store';
// eslint-disable-next-line import/no-cycle
import useForm from '../../hooks/useForm';
import { flattenFormFields } from '../../utils/utils';

export default function FieldsGroup({ formField, index }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['form']);
  const {
    selectedFormData,
    setSelectedFormData,
    selectedFormValues,
    setSelectedFormValues,
  } = useStore((state) => state);
  const {
    fields: { groupTitle, groupFields },
  } = formField;
  const { getFormFields } = useForm();

  const formFields = _.get(selectedFormData, 'form.fields.formFields', []);
  const multiSiteOrderValue = _.get(selectedFormValues, 'MultiSiteOrder.value');

  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
  const getGroupSite = (i) => _.get(i, 'fields.groupKey') === 'GroupSite';
  const firstIndex = formFields.findIndex(getGroupSite);
  const lastIndex = formFields.findLastIndex(getGroupSite);
  const currentIndex = index - firstIndex + 1;
  const sitesCount = lastIndex - firstIndex + 1;
  const isMultiSiteOrder = multiSiteOrderValue === t('yes');

  useEffect(() => {
    if (!isMultiSiteOrder && sitesCount > 1) {
      formFields.splice(firstIndex + 1, sitesCount - 1);
      const selectedFormDataCopy = deepCopy(selectedFormData);
      _.set(selectedFormDataCopy, 'form.fields.formFields', formFields);
      setSelectedFormData(selectedFormDataCopy);
      // @Todo: Delete values from the setSelectedFormValues
    }
  }, [isMultiSiteOrder]);

  const deleteSite = () => {
    formFields.splice(lastIndex, 1);
    const selectedFormDataCopy = deepCopy(selectedFormData);
    _.set(selectedFormDataCopy, 'form.fields.formFields', formFields);
    setSelectedFormData(selectedFormDataCopy);
    setIsOpen(false);
  };

  const updateGroupField = (item) => {
    const result = { ...item };
    const itemFields = _.get(item, 'fields');
    const updatedFields = { ...itemFields };
    const newIndex = sitesCount + 1;

    // Add groupId
    _.set(updatedFields, 'groupId', `Site ${newIndex}`);

    // Update id
    const itemId = _.get(item, 'sys.id');
    if (itemId) _.set(result, 'sys.id', `${itemId}${newIndex}`);

    // Update name
    const itemName = _.get(itemFields, 'name');
    if (itemName) _.set(updatedFields, 'name', `${itemName}${newIndex}`);

    // Update appearAfter values
    const appearAfter = _.get(itemFields, 'appearAfter.fields.matchAllFields');
    if (appearAfter) {
      const updatedAppearAfter = appearAfter.map((i) => {
        const field = _.get(i, 'fields.field');
        if (field) {
          const name = _.get(field, 'fields.name');
          _.set(i, 'fields.field.fields.name', name.concat(newIndex));
        }
        return i;
      });
      _.set(
        updatedFields,
        'appearAfter.fields.matchAllFields',
        updatedAppearAfter
      );
    }

    _.set(result, 'fields', updatedFields);

    return result;
  };

  const handleButtonSite = (type) => {
    if (type === 'add') {
      const formFieldsCopy = [...formFields];
      const groupSiteElement = formFieldsCopy.find(getGroupSite);
      const newElement = deepCopy(groupSiteElement);
      const updatedGroupFields = _.get(newElement, 'fields.groupFields').map(
        updateGroupField
      );

      // Add a new field into fields
      _.set(newElement, 'fields.groupFields', updatedGroupFields);
      formFieldsCopy.splice(lastIndex + 1, 0, newElement);

      // Update SelectedFormData store
      const selectedFormDataCopy = deepCopy(selectedFormData);
      _.set(selectedFormDataCopy, 'form.fields.formFields', formFieldsCopy);
      setSelectedFormData(selectedFormDataCopy);

      // Update SelectedFormValues store
      const formFieldValues = updatedGroupFields.reduce(flattenFormFields, {});
      setSelectedFormValues({ ...selectedFormValues, ...formFieldValues });
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div>
      <div className="form-field--group--main">
        <h3>
          {groupTitle}
          {sitesCount > 1 && ` ${currentIndex}`}
        </h3>
        <StackView space={4}>{getFormFields(groupFields)}</StackView>
      </div>
      {index === lastIndex && (
        <div style={{ display: 'flex', gap: '1em' }}>
          {isMultiSiteOrder && (
            <Button onPress={() => handleButtonSite('add')}>
              {t('addSite')}
            </Button>
          )}
          {sitesCount > 1 && index === lastIndex && (
            <Button onPress={() => handleButtonSite('remove')}>
              {t('deleteSite')}
            </Button>
          )}
        </div>
      )}
      <Modal
        isOpen={isOpen}
        heading={t('confirmation')}
        bodyText={t('areYouSure')}
        confirmButtonText={t('no')}
        cancelButtonText={t('yes')}
        onConfirm={() => setIsOpen(false)}
        onCancel={() => deleteSite()}
        closeButton={null}
      />
    </div>
  );
}

FieldsGroup.propTypes = {
  formField: PropTypes.shape({
    fields: PropTypes.shape({
      groupTitle: PropTypes.string,
      groupFields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
};
