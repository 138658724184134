/* eslint-disable no-useless-catch */
import { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Upload as UploadIcon } from '@telus-uds/palette-allium/build/web/icons';
import { Icon } from '@telus-uds/components-web';

import useStore from '../../../store/store';
import { typeMapping, defaultFileTypes, getFileError } from '../util';

export default function Upload({ fieldData }) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );

  const { t } = useTranslation(['form']);
  const { name, required, fileTypes } = fieldData;
  const { error: fieldError } = selectedFormValues[name] || {};

  const MAXIMUM_FILE_SIZE = 5;
  const acceptedFileTypes = fileTypes || defaultFileTypes;

  const checkForError = (fileData) => {
    const { type, size } = fileData;
    const errors = [];
    /**
     * Checking for file type
     */
    const fileType = typeMapping[type];
    if (!acceptedFileTypes.includes(fileType))
      errors.push({ message: `File type is not accepted` });

    if (size > MAXIMUM_FILE_SIZE * 1000000)
      errors.push({
        message: `File size cannot exceed ${MAXIMUM_FILE_SIZE} MB`,
      });

    return errors;
  };

  const handleFiles = async (filesData) => {
    const modifiedFileData = Array.from(filesData).map((fileData) => {
      return {
        file: fileData,
        errors: checkForError(fileData),
      };
    });

    setSelectedFormValues((previousValue) => {
      const allFiles = [...previousValue[name].value, ...modifiedFileData];
      const error = getFileError(allFiles, t, required);

      return {
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: [...previousValue[name].value, ...modifiedFileData],
          touched: true,
          ...{
            error: error || '',
          },
        },
      };
    });
  };

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  const handleOnClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      className="form-field__file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        id="file-upload-input"
        ref={inputRef}
        type="file"
        className="form-field__file-upload__input"
        multiple
        onChange={handleChange}
        accept={acceptedFileTypes.join(',')}
      />
      <label
        className={`form-field__file-upload__label ${
          dragActive && 'drag-active'
        } ${fieldError && 'error'}`}
        htmlFor="file-upload-input"
      >
        <Icon icon={UploadIcon} />
        <p className="form-field__file-upload__label__text">
          Drag &apos;n&apos; drop some files here, or
          <button
            type="button"
            className="form-field__file-upload__label__upload-button"
            onClick={handleOnClick}
          >
            click
          </button>
          to select files
        </p>
        <p className="form-field__file-upload__label__accepted-type">
          Accepted file types {acceptedFileTypes.join(', ')}
        </p>
        <p className="form-field__file-upload__label__accepted-type">
          Accepted file size {MAXIMUM_FILE_SIZE}MB
        </p>
      </label>
      {dragActive && (
        <div
          className="form-field__file-upload__drag"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </form>
  );
}

Upload.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    fileTypes: PropTypes.arrayOf(PropTypes.string),
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
