import useStore from '../../../store/store';

export default function Greeting() {
  const { userProfile } = useStore((state) => state);
  const { first_name: firstName, last_name: lastName } = userProfile;

  return (
    <p>
      Welcome {firstName} {lastName}
    </p>
  );
}
