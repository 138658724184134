import InputText from './fields/inputText';
import InputNumber from './fields/inputNumber';
import PhoneNumber from './fields/phoneNumber';
import Email from './fields/email';
import Select from './fields/select';
import Radio from './fields/radio';
import Date from './fields/date';
import Checkbox from './fields/checkbox';
import CheckboxGroup from './fields/checkboxGroup';
import Address from './fields/address';
import TextArea from './fields/textArea';
import File from './fields/file';

const fieldTypeMapping = {
  InputText,
  InputNumber,
  PhoneNumber,
  Email,
  Select,
  Radio,
  Date,
  Checkbox,
  CheckboxGroup,
  Address,
  TextArea,
  File,
};

export default fieldTypeMapping;
