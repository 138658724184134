import _, { isArray } from 'lodash';
import { StackView } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../store/store';
import { getLocale } from '../utils/utils';
import FormFields from '../components/formFields';
// eslint-disable-next-line import/no-cycle
import FieldsGroup from '../components/formFields/fieldsGroup';
import {
  DEFAULT_ADDRESS_OBJECT,
  REQUIRED_ADDRESS_FIELDS,
} from '../utils/constants';
import { validateField } from '../components/formFields/util';
import { getFileError } from '../components/fileUpload/util';
import { postRequest } from '../config/apiClient';

export default function useForm() {
  const { t } = useTranslation(['form']);
  const {
    userProfile,
    selectedFormData,
    selectedFormValues,
    setSelectedFormValues,
    setShowOverlay,
    setFormSubmissionStatus,
  } = useStore((state) => state);

  const formFields = _.get(selectedFormData, 'form.fields.formFields', []);

  const checkVisibilityCondition = (checkItem) => {
    const {
      fields: {
        field: {
          fields: { name },
        },
        listOfValuesToMatch,
      },
    } = checkItem;
    const checkItemValid = listOfValuesToMatch.some((checkValueItem) => {
      const {
        fields: { value: expectedValue },
      } = checkValueItem;
      const { value: actualValue } = selectedFormValues[name];

      if (isArray(actualValue)) return actualValue.includes(expectedValue);

      return expectedValue === actualValue;
    });

    return checkItemValid;
  };

  const isFieldVisible = (fieldData) => {
    const { appearAfter } = fieldData;
    if (!appearAfter) return true;
    const {
      fields: { matchAllFields, matchEitherField },
    } = appearAfter;

    const matchAllFieldsCriteriaMeet = matchAllFields
      ? matchAllFields.every(checkVisibilityCondition)
      : true;
    const matchEitherFieldCriteriaMeet = matchEitherField
      ? matchEitherField.some(checkVisibilityCondition)
      : true;

    return matchAllFieldsCriteriaMeet && matchEitherFieldCriteriaMeet;
  };

  const uploadAttachments = async (files) => {
    const formData = files.reduce((acc, file) => {
      acc.append('attachments', file);
      return acc;
    }, new FormData());

    const result = await postRequest('smbforms/upload', formData, {
      'Content-Type': 'multipart/form-data',
    });
    return result;
  };

  const getSalesRepInfo = () => {
    const {
      first_name: firstName,
      last_name: lastName,
      email,
      cpms_id: cpmsId,
      outlet,
      channels,
      provinces,
      xid,
      store_code: storeCode,
      dealership,
      role,
      sales_manager: salesManager,
      sales_manager_email: salesManagerEmail,
    } = userProfile;

    return {
      firstName,
      lastName,
      email,
      cpmsId,
      outlet,
      channels: channels.map(({ name }) => name),
      provinces: provinces.map(({ name }) => name),
      xid,
      storeCode,
      dealership,
      role: role.name,
      salesManager,
      salesManagerEmail,
    };
  };

  const validateForm = () => {
    const formFieldsCopy = { ...selectedFormValues };
    let isValid = true;
    const invalidElementIds = [];

    Object.keys(formFieldsCopy).forEach((fieldKey) => {
      const fieldData = formFieldsCopy[fieldKey];
      const { id, type, required, value, fieldValidations } = fieldData;

      const isVisible = isFieldVisible(formFieldsCopy[fieldKey]);

      if (isVisible) {
        /**
         * Checking for Required
         */
        if (
          required &&
          (!value || (type === 'CheckboxGroup' && !value.length))
        ) {
          formFieldsCopy[fieldKey].error = t('labelFieldRequired');
          invalidElementIds.push(id);
          isValid = false;
        }

        if (type === 'Date' && value === 'Invalid date') {
          formFieldsCopy[fieldKey].error = t('labelInvalidDate');
          invalidElementIds.push(id);
          isValid = false;
        }

        if (type === 'Address' && required) {
          const validAddress = REQUIRED_ADDRESS_FIELDS.every(
            (key) => !!value[key]
          );

          if (!validAddress) {
            formFieldsCopy[fieldKey].error = t('labelFieldRequired');
            invalidElementIds.push(id);
            isValid = false;
          }
        }

        if (type === 'File') {
          if (required) {
            const errorMessage = getFileError(value, t, true);
            if (errorMessage) {
              formFieldsCopy[fieldKey].error = errorMessage;
              invalidElementIds.push(id);
              isValid = false;
            }
          }
        }

        /**
         * Checking for rest of validations
         */
        if (value && fieldValidations) {
          const fieldError = validateField(fieldData);
          if (fieldError) {
            formFieldsCopy[fieldKey].error = fieldError;
            invalidElementIds.push(id);
            isValid = false;
          }
        }
      }
    });

    if (!isValid) {
      if (invalidElementIds.length >= 1)
        document.getElementById(invalidElementIds[0]).scrollIntoView();
      setSelectedFormValues(formFieldsCopy);
    }

    return isValid;
  };

  const resetForm = () => {
    const formFieldsClone = Object.keys(selectedFormValues).reduce(
      (acc, current) => {
        const fieldData = selectedFormValues[current];
        const { type } = fieldData;
        const fieldValue = type === 'Address' ? DEFAULT_ADDRESS_OBJECT : '';
        return { ...acc, [current]: { ...fieldData, value: fieldValue } };
      },
      {}
    );
    setSelectedFormValues(formFieldsClone);
  };

  const getCcEmailList = (items) =>
    items && items.length
      ? items.map((item) => _.get(item, 'fields.emailId', '')).join(',')
      : '';

  const getPayload = () => {
    const {
      form: {
        fields: { formKey },
      },
      emailSubject,
      recipientEmailAddress,
      ccEmailAddress,
    } = selectedFormData;
    const formData = Object.keys(selectedFormValues).reduce((acc, current) => {
      const fieldData = selectedFormValues[current];
      const { name, label, value, type, groupId, appearsInOutput } = fieldData;
      if (appearsInOutput && type !== 'File')
        return { ...acc, [name]: { label, value, groupId } };
      if (type === 'File')
        return { ...acc, files: [..._.get(acc, 'files', []), ...value] };
      return { ...acc };
    }, {});

    const files = [..._.get(formData, 'files', [])]
      .filter((file) => !file.errors.length)
      .map((file) => file.file);
    delete formData.files;

    const payload = {
      salesRepInfo: getSalesRepInfo(),
      emailSubject,
      recipientEmailAddress,
      ccEmailAddress: getCcEmailList(ccEmailAddress),
      formData,
      files,
      locale: getLocale(),
      formKey,
    };
    delete payload.form;
    return payload;
  };

  const handleFormSubmit = async () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      setShowOverlay(true);
      const payload = getPayload();
      const hasAttachments = !!payload.files.length;
      try {
        const fileUploadResult = hasAttachments
          ? await uploadAttachments(payload.files)
          : false;

        if (!hasAttachments || (hasAttachments && fileUploadResult)) {
          if (hasAttachments) {
            const {
              data: { files },
            } = fileUploadResult;
            payload.files = [...files];
          }

          const result = await postRequest('smbforms', payload);

          if (result) {
            resetForm();
            setFormSubmissionStatus({
              visible: true,
              type: 'success',
              message: t('formSubmissionSuccess'),
            });
          }
        }
      } catch (err) {
        setFormSubmissionStatus({
          visible: true,
          type: 'error',
          message: t('formSubmissionError'),
        });
      } finally {
        setShowOverlay(false);
      }
    }
  };

  const getFormFields = (fieldsArray) =>
    fieldsArray.map((formField, index) => {
      const {
        fields,
        sys: {
          id,
          contentType: {
            sys: { id: fieldType },
          },
        },
      } = formField;
      const key = `${id}${index}`;
      const fieldData = {
        ...fields,
        id,
        category: fieldType,
      };

      if (fieldType === 'formField' && !isFieldVisible(fields)) return null;

      if (fieldType === 'formFieldsGroup')
        return <FieldsGroup key={key} formField={formField} index={index} />;

      return <FormFields key={key} fieldData={fieldData} />;
    });

  const renderFormFields = () => {
    if (!selectedFormValues) return null;

    return <StackView space={4}>{getFormFields(formFields)}</StackView>;
  };

  return {
    renderFormFields,
    handleFormSubmit,
    getFormFields,
  };
}
