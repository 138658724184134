/* eslint-disable import/no-cycle */
import React from 'react';

import PropTypes from 'prop-types';

import fieldTypeMapping from './fieldMapping';

export default function FormFields({ fieldData }) {
  const { type } = fieldData;

  const field = React.createElement(fieldTypeMapping[type], { fieldData });

  return field;
}

FormFields.propTypes = {
  fieldData: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};
