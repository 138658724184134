import { useState, useEffect, useCallback } from 'react';

import { createClient } from 'contentful';

import useStore from '../store/store';

export default function useGetContentfulData(formUrl) {
  const [data, setData] = useState();
  const [error, setError] = useState();

  const { locale } = useStore((state) => state);

  const fetchData = useCallback(async () => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    });

    try {
      const result = await client.getEntries({
        include: 8,
        limit: 1000,
        content_type: 'page',
        ...(formUrl && { 'fields.pageUrl': `/${formUrl}` }),
        locale,
      });
      if (result) {
        const { items } = result;
        setData(items);
      }
    } catch (e) {
      setError(e.message);
    }
  }, [locale, formUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData, formUrl]);

  return {
    error,
    data,
  };
}
