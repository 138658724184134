/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from '@telus-uds/components-web';

import { useTranslation } from 'react-i18next';
import useStore from '../../../store/store';
import { getLabel } from '../util';

export default function Date({ fieldData }) {
  const { t } = useTranslation(['form']);
  const { selectedFormValues, setSelectedFormValues, locale } = useStore(
    (state) => state
  );

  const { id, name, hint, toolTip } = fieldData;
  const { value: fieldValue, error } = selectedFormValues[name];

  const handleOnChange = (value) => {
    const dateValue = moment(value).utc().format('MM/DD/YYYY');
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        value: dateValue,
        touched: true,
        ...(dateValue === 'Invalid date'
          ? { error: t('labelInvalidDate') }
          : { error: '' }),
      },
    }));
  };

  const fieldProps = {
    copy: locale.split('-')[0],
    id: name,
    label: getLabel(fieldData),
    ...(hint && { hint, hintPosition: 'below' }),
    ...(error && { validation: 'error', feedback: error }),
    ...(toolTip && { tooltip: toolTip }),
    onDateChange: handleOnChange,
    value: fieldValue,
  };

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: '',
        },
      }));
    };
  }, []);

  return (
    <div id={id}>
      <DatePicker {...fieldProps} />
    </div>
  );
}

Date.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    toolTip: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
