/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';

import FieldTemplate from './fieldTemplate';

export default function PostalCode({ fieldData }) {
  const fieldProps = {
    parentFieldData: fieldData,
    fieldData: {
      fieldKey: 'postalCode',
      fieldLabel: 'Postal Code',
      fieldRequired: true,
    },
  };
  return <FieldTemplate {...fieldProps} />;
}

PostalCode.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
