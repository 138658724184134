import { FlexGrid } from '@telus-uds/components-web';

export default function Footer() {
  return (
    <FlexGrid>
      <FlexGrid.Row>
        <FlexGrid.Col xs={12} sm={12} md={12} lg={12}>
          <div className="footer">
            <p>&#169; 2023 TELUS</p>
          </div>
        </FlexGrid.Col>
      </FlexGrid.Row>
    </FlexGrid>
  );
}
