import PropTypes from 'prop-types';
import { Select } from '@telus-uds/components-web';

import { UNIT_TYPES } from '../../../../../utils/constants';
import useStore from '../../../../../store/store';

export default function UnitType({ fieldData }) {
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );
  const { name } = fieldData;
  const { value: fieldValue } = selectedFormValues[name];

  const handleOnChange = (key, value) => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        value: { ...fieldValue, [key]: value },
      },
    }));
  };

  return (
    <Select
      label="Unit Type"
      placeholder="Select a unit type..."
      value={fieldValue.unitType}
      onChange={(value) => handleOnChange('unitType', value)}
    >
      <Select.Item value="">..</Select.Item>
      {UNIT_TYPES.map((unitType) => (
        <Select.Item key={`unit-type-${unitType}`} value={unitType}>
          {unitType}
        </Select.Item>
      ))}
    </Select>
  );
}

UnitType.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
