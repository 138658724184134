import PropTypes from 'prop-types';
import { Box, Button, Spacer, Typography } from '@telus-uds/components-web';
import { useNavigate } from 'react-router-dom';

import useStore from '../../../store/store';

import {
  EXTERNAL_USERS,
  INTERNAL_USERS,
  CHANNELS,
} from '../../../utils/constants';

export default function FormListItem({ formData }) {
  const navigate = useNavigate();
  const { locale, userProfile } = useStore((state) => state);
  const { channelName } = userProfile;
  const { DEALER } = CHANNELS;

  const {
    fields: { pageTitle, pageDescription, pageUrl, visibility },
  } = formData;

  /** If the webform is not visible to either INTERNAL or EXTERNAL users Return NULL */
  if (
    (visibility === EXTERNAL_USERS && channelName !== DEALER) ||
    (visibility === INTERNAL_USERS && channelName === DEALER)
  )
    return null;

  return (
    <div className="form-card">
      <Box vertical={2}>
        <Typography block variant={{ size: 'h2' }}>
          {pageTitle}
        </Typography>
        <Spacer space={4} />
        <Box>
          <Typography>{pageDescription}</Typography>
          <Box top={4}>
            <Button
              onPress={() => navigate(`/${locale}${pageUrl}`)}
              variant={{ priority: 'high', width: 'full' }}
            >
              Access Now
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

FormListItem.propTypes = {
  formData: PropTypes.shape({
    fields: PropTypes.shape({
      pageTitle: PropTypes.string,
      pageDescription: PropTypes.string,
      pageUrl: PropTypes.string,
      visibility: PropTypes.string,
    }),
  }).isRequired,
};
