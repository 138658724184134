/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { RadioGroup, StackView } from '@telus-uds/components-web';

import useStore from '../../../store/store';
import { getLabel } from '../util';

export default function Radio({ fieldData }) {
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );

  const { id, name, options, hint, toolTip } = fieldData;
  const { error } = selectedFormValues[name];

  const optionsItems =
    options &&
    options.map((option) => ({
      label: option.fields.label,
      id: name + option.fields.value,
    }));

  const handleOnChange = (value) => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        value: value.replace(name, ''),
        touched: true,
        error: '',
      },
    }));
  };

  const fieldProps = {
    legend: getLabel(fieldData),
    ...(hint && { hint, hintPosition: 'below' }),
    ...(error && { validation: 'error', feedback: error }),
    ...(toolTip && { tooltip: toolTip }),
    items: optionsItems,
    checkedId: name + selectedFormValues[name].value,
    onChange: handleOnChange,
  };

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: '',
        },
      }));
    };
  }, []);

  return (
    <div id={id}>
      <StackView space={3}>
        <RadioGroup {...fieldProps} />
      </StackView>
    </div>
  );
}

Radio.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    toolTip: PropTypes.string,
    error: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};
