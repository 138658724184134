/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import useStore from '../../../store/store';
import AddressAutoComplete from '../../addressAutoComplete';
import { DEFAULT_ADDRESS_OBJECT } from '../../../utils/constants';

export default function Address({ fieldData }) {
  const { setSelectedFormValues } = useStore((state) => state);

  const { name } = fieldData;

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: { ...DEFAULT_ADDRESS_OBJECT },
        },
      }));
    };
  }, []);

  return <AddressAutoComplete fieldData={fieldData} />;
}

Address.propTypes = {
  fieldData: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
