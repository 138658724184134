/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { TextInput } from '@telus-uds/components-web';
import { useTranslation } from 'react-i18next';

import useStore from '../../../store/store';
import { getLabel } from '../util';

export default function PhoneNumber({ fieldData }) {
  const { t } = useTranslation(['form']);
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );

  const { id, name, toolTip, required, hint } = fieldData;
  const { value: fieldValue, error } = selectedFormValues[name];

  const isPhoneNumberValid = (number) => {
    const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(number);
  };

  const getFormattedPhoneNumber = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };

  const handleOnChange = (value) => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        value: getFormattedPhoneNumber(value),
        ...{
          error:
            previousValue[name].touched && !isPhoneNumberValid(value)
              ? t('labelInvalidPhoneNumber')
              : '',
        },
      },
    }));
  };

  const handleOnBlur = () => {
    setSelectedFormValues((previousValue) => ({
      ...previousValue,
      [name]: {
        ...previousValue[name],
        touched: true,
        ...(required && !isPhoneNumberValid(fieldValue)
          ? {
              error: !fieldValue
                ? t('labelFieldRequired')
                : t('labelInvalidPhoneNumber'),
            }
          : { error: '' }),
      },
    }));
  };

  const fieldProps = {
    label: getLabel(fieldData),
    ...(hint && { hint, hintPosition: 'below' }),
    ...(error && { validation: 'error', feedback: error }),
    ...(toolTip && { tooltip: toolTip }),
    value: selectedFormValues[name].value || '(___) ___-____',
    onChange: handleOnChange,
    onBlur: handleOnBlur,
  };

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: '',
        },
      }));
    };
  }, []);

  return (
    <div id={id}>
      <TextInput {...fieldProps} />
    </div>
  );
}

PhoneNumber.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
