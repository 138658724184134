import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const getLabel = (fieldData) => {
  const { label, required } = fieldData;

  if (!required) return label;

  return `${label} *`;
};

export const validateField = (fieldData) => {
  const { value: fieldValue, fieldValidations } = fieldData;

  if (!fieldValidations) return false;

  const errors = [];
  fieldValidations.forEach((fieldValidation) => {
    const {
      fields: { type, value, message },
    } = fieldValidation;
    switch (type) {
      case 'Min':
        if (+fieldValue < +value) errors.push(message);
        break;
      case 'Max':
        if (+fieldValue > +value) errors.push(message);
        break;
      case 'MinLength':
        if (fieldValue.length < +value) errors.push(message);
        break;
      case 'MaxLength':
        if (fieldValue.length > +value) errors.push(message);
        break;
      case 'Pattern': {
        const regex = new RegExp(value);
        if (!regex.test(fieldValue)) errors.push(message);
        break;
      }
      default:
        break;
    }
  });
  return !!errors.length && errors[0];
};

export const getRichText = (data) => documentToReactComponents(data);
