import React from 'react';

import PropTypes from 'prop-types';

export default function Alert({ message }) {
  return <div className="alert">{message}</div>;
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
};
