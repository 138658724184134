import { useTranslation } from 'react-i18next';
import { Spacer, Typography } from '@telus-uds/components-web';

import FormList from './components/formList';

export default function Dashboard() {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Typography block variant={{ size: 'display2' }} heading="h1">
        {t('welcomeMessage')}
      </Typography>
      <Spacer space={6} />
      <FormList />
    </>
  );
}
