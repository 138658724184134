import PropTypes from 'prop-types';
import { FlexGrid, Spacer } from '@telus-uds/components-web';

import StreetNumber from './components/streetNumber';
import StreetName from './components/streetName';
import UnitNumber from './components/unitNumber';
import UnitType from './components/unitType';
import City from './components/city';
import Province from './components/province';
import PostalCode from './components/postalCode';

export default function Fields({ fieldData }) {
  return (
    <>
      <FlexGrid.Row>
        <FlexGrid.Col xs={6}>
          <StreetNumber fieldData={fieldData} />
        </FlexGrid.Col>
        <FlexGrid.Col xs={6}>
          <StreetName fieldData={fieldData} />
        </FlexGrid.Col>
      </FlexGrid.Row>
      <Spacer space={3} />
      <FlexGrid.Row>
        <FlexGrid.Col xs={6}>
          <UnitNumber fieldData={fieldData} />
        </FlexGrid.Col>
        <FlexGrid.Col xs={6}>
          <UnitType fieldData={fieldData} />
        </FlexGrid.Col>
      </FlexGrid.Row>
      <Spacer space={3} />
      <FlexGrid.Row>
        <FlexGrid.Col xs={4}>
          <City fieldData={fieldData} />
        </FlexGrid.Col>
        <FlexGrid.Col xs={4}>
          <Province fieldData={fieldData} />
        </FlexGrid.Col>
        <FlexGrid.Col xs={4}>
          <PostalCode fieldData={fieldData} />
        </FlexGrid.Col>
      </FlexGrid.Row>
    </>
  );
}

Fields.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
