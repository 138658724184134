import React from 'react';

import PropTypes from 'prop-types';

import SingleFile from './singleFile';

export default function FileList({ files, fieldName, fieldError }) {
  return (
    <ul
      className={`form-field__file-upload__file-list ${fieldError && 'error'}`}
    >
      {files.map((fileData, index) => (
        <SingleFile
          key={`${fileData.file.name}${fileData.file.size}`}
          fileData={fileData}
          fileIndex={index}
          fieldName={fieldName}
        />
      ))}
    </ul>
  );
}

FileList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.file,
      errors: PropTypes.arrayOf(
        PropTypes.shape({
          message: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldError: PropTypes.string.isRequired,
};
