export const DEFAULT_ADDRESS_OBJECT = {
  streetNumber: '',
  streetName: '',
  unitNumber: '',
  unitType: '',
  city: '',
  province: '',
  postalCode: '',
};

export const REQUIRED_ADDRESS_FIELDS = [
  'streetNumber',
  'streetName',
  'city',
  'province',
  'postalCode',
];

export const UNIT_TYPES = [
  'Apartment',
  'Back',
  'Basement',
  'Bay',
  'Bureau',
  'Floor',
  'Lobby',
  'Lower',
  'Penthouse',
  'Rear',
  'Room',
  'Side',
  'Station',
  'Suite',
  'Unit',
  'Upper',
];

export const CHANNELS = {
  DEALER: 'Dealer',
};

export const INTERNAL_USERS = 'Internal users only';
export const EXTERNAL_USERS = 'External users only';
