export default function getAddressObject(addressComponents) {
  const addressMap = {
    streetNumber: ['street_number'],
    postalCode: ['postal_code'],
    streetName: ['street_address', 'route'],
    province: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
  };

  const address = {
    streetNumber: '',
    postalCode: '',
    streetName: '',
    province: '',
    city: '',
  };
  addressComponents.forEach((addressComponent) => {
    const { types } = addressComponent;

    Object.keys(addressMap).forEach((key) => {
      const typesTocheckFor = addressMap[key];
      const result = typesTocheckFor.some((item) => types.includes(item));
      if (result) address[key] = addressComponent.long_name;
    });
  });
  return address;
}
