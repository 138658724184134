import useGetContentfulData from '../../../hooks/useGetContentfulData';
import FormListItem from './formListItem';

export default function FormList() {
  const { data: contentfulData } = useGetContentfulData();

  return (
    <div className="form-card-container">
      {contentfulData &&
        contentfulData
          .reverse()
          .map((row) => <FormListItem key={row.sys.id} formData={row} />)}
    </div>
  );
}
