import PropTypes from 'prop-types';
import { Typography } from '@telus-uds/components-web';

export default function Heading({ fieldData }) {
  const { label } = fieldData;
  return (
    <Typography block variant={{ size: 'large' }}>
      {label}
    </Typography>
  );
}

Heading.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
