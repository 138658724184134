import { Link } from 'react-router-dom';

import useStore from '../../store/store';
import NavBar from './components/navBar';
import Greeting from './components/greeting';

import logo from '../../assets/svg/Telus_logo.svg';

export default function Header() {
  const { locale, userProfile } = useStore((state) => state);
  return (
    <header className="header">
      {userProfile && <NavBar />}
      <div className="container header__sub-section">
        <Link to={`/${locale}`}>
          <img
            className="header__sub-section__logo"
            src={logo}
            alt="Telus Logo"
          />
        </Link>
        {userProfile && <Greeting />}
      </div>
    </header>
  );
}
