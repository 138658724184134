/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Search as TelusSearch } from '@telus-uds/components-web';

import useStore from '../../../store/store';
import getAddressObject from '../util';
import { REQUIRED_ADDRESS_FIELDS } from '../../../utils/constants';

export default function Search({ fieldData }) {
  const { selectedFormValues, setSelectedFormValues } = useStore(
    (state) => state
  );
  const [addressQuery, setAddressQuery] = useState('');
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const stateRef = useRef();
  stateRef.current = selectedFormValues;

  const { name } = fieldData;
  const { value: fieldValue } = selectedFormValues[name];

  const handlePlaceChange = async () => {
    const place = await autoCompleteRef.current.getPlace();
    const { address_components: addressComponents } = place;
    const addressObject = getAddressObject(addressComponents);
    const addreddObjectValid = REQUIRED_ADDRESS_FIELDS.every(
      (key) => !!addressObject[key]
    );
    const formValuesClone = {
      ...stateRef.current,
    };

    formValuesClone[name].value = { ...fieldValue, ...addressObject };
    formValuesClone[name].touched = true;
    if (addreddObjectValid) formValuesClone[name].error = '';

    setAddressQuery('');
    setSelectedFormValues(formValuesClone);
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { types: ['geocode'], componentRestrictions: { country: 'ca' } }
    );
    autoCompleteRef.current.setFields([
      'address_components',
      'formatted_address',
    ]);
    autoCompleteRef.current.addListener('place_changed', handlePlaceChange);
  }, []);

  return (
    <TelusSearch
      placeholder="search address..."
      ref={inputRef}
      value={addressQuery}
      onChange={(value) => setAddressQuery(value)}
      onClear={() => setAddressQuery('')}
    />
  );
}

Search.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
