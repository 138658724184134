/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import FieldTemplate from './fieldTemplate';

export default function StreetNumber({ fieldData }) {
  const fieldProps = {
    parentFieldData: fieldData,
    fieldData: {
      fieldKey: 'streetNumber',
      fieldLabel: 'Street Number',
      fieldRequired: true,
    },
  };
  return <FieldTemplate {...fieldProps} />;
}

StreetNumber.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({}),
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
