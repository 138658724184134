/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import useStore from '../../../store/store';
// import MultiFileUpload from '../../multiFileUpload';

import FileUpload from '../../fileUpload';

export default function File({ fieldData }) {
  const { setSelectedFormValues } = useStore((state) => state);

  const { name } = fieldData;

  useEffect(() => {
    return () => {
      setSelectedFormValues((previousValue) => ({
        ...previousValue,
        [name]: {
          ...previousValue[name],
          value: '',
        },
      }));
    };
  }, []);

  return (
    <>
      <FileUpload fieldData={fieldData} />
      {/* <MultiFileUpload fieldData={fieldData} /> */}
    </>
  );
}

File.propTypes = {
  fieldData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    hint: PropTypes.string,
    required: PropTypes.bool,
    toolTip: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};
